import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/template-partials/global-components/section"
import InteriorPageHeader from "../components/template-partials/global-components/interior-page-header"
import aboutBG from "../images/1.0_backgroundheader.jpg"
import legalRound from "../images/legal-round.jpg";

const TermsAndConditionsPage = () => {

  return (
    <Layout>
      <SEO title="Neuroblastoma-Info Terms of Use" description="" />

      <InteriorPageHeader backgroundImage={aboutBG} roundImage={legalRound} alt="Terms of Use">
        <h1 className="section__heading section__heading--green h1">Terms of Use</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page lg_pb-5">
        <div className="row row--inner">
          <div className="columns">
            <div className="row">
              <div className="columns">
                <p><strong>Terms of Use Agreement</strong></p>
                <p>This website may contain information about United Therapeutics Corporation, its subsidiaries and affiliates (collectively, "Company"), and/or one or more product marketed by Company. Access to and use of this website is subject to the following terms and conditions and all applicable laws. By accessing and using the website, you agree to be bound by and to comply with this Terms of Use Agreement without limitation or qualification and you acknowledge that it supersedes any other agreement between you and Company regarding this subject matter. If you do not agree with these Terms of Use, please exit and do not use the website. These terms of use may be revised by Company from time to time and you should therefore review them on your next visit to the website. Your continued use of the website following any such posting will be deemed as consent to the new notice, term or condition. </p>
                <p>a)	You may browse this website. You may download and print material from this website, provided that you retain all copyright and trademark notices contained in such materials. You may use such downloaded and printed materials for your own personal, non-commercial use. You may not use in any manner such materials for public or commercial purposes without Company's express written consent.</p>
                <p>b)	All product names, logos and service marks displayed on this website that are identified by “™” or “®”, or that appear in type form different from that of the surrounding text are trademarks owned by or licensed to Company or our affiliates (collectively, "Trademarks"), unless otherwise identified as being owned by another entity. Nothing contained herein shall be construed as conferring by implication, estoppel or otherwise any license or right, either express or implied, under any patent or Trademark of Company or any third party. No use of any Trademark may be made without the prior written authorization of Company, except to identify the product or services of the company.</p>
                <p>c)	Company's products are available only by prescription from licensed health care professionals. Company does not provide medical advice or services.</p>
                <p>d)	You should assume that all content of the website is protected by copyright law unless otherwise noted and may not be used except as provided herein, or with the express written permission of Company. Except as expressly provided above, nothing contained herein shall be construed as conferring any license or right under any Company copyright.</p>
                <p>e)	You are prohibited from posting or transmitting to the website any unlawful, threatening, libelous, defamatory, obscene, inflammatory, pornographic or profane material or any material that could constitute or encourage conduct that would be considered a criminal offense, give rise to civil liability or would otherwise violate the law. Company will fully cooperate with any law enforcement authorities or court order requesting or directing Company to disclose the identity of anyone posting any such information or materials. </p>
                <p><strong>Disclaimer</strong></p>
                <p>COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND OR NATURE WITH RESPECT TO ANY OF THE INFORMATION CONTAINED ON THIS WEBSITE. COMPANY DISCLAIMS ALL REPRESENTATIONS AND/OR WARRANTIES, EXPRESS OR IMPLIED, CREATED BY LAW, CONTRACT OR OTHERWISE, INCLUDING WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NONINFRINGEMENT.</p>
                <p>In no event shall Company be liable for any damages of any kind or nature, including without limitation, direct, indirect, special, consequential or incidental damages arising from or in connection with the existence or use of the website and/or the information.</p>
              </div>
            </div>
          </div>
        </div>
      </Section>

    </Layout>
  )
}

export default TermsAndConditionsPage
